import React from "react";
import USA_flag from "../../assets/images/usa_flag_24.png";
import IR_flag from "../../assets/images/iran_flag_24.png";
import Germany_flag from "../../assets/images/germany_flag_24.png";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { useLanguage } from "../../context/LanguageContext";

function Header() {
  const { language, changeLanguage } = useLanguage();

  const content = {
    FA: {
      Contact: "ارتباط با ما: ",
      text: " شماره تماس: 33 35 356 0935",
      email:"ایمیل: info@jvcjam.com",
    },
    EN: {
      Contact: "Contact: ",
      text: "Phone 09 35 356 35 33",
      email:"Email: info@jvcjam.com",
    },
    DE: {
      Contact: "Contact DE: ",
      text: "Phone 09 35 356 35 33",
      email:"Email: info@jvcjam.com",
    },
  };

  const currentContent = content[language] || content["FA"];

  return (
    <div
      className={` bg-black text-white ${language === "FA" ? "rtl" : "ltr"}`}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
        <div className="text-lg font-semibold text-gray-100">
          <span>{currentContent.Contact}</span>
          <span className="px-2">{currentContent.text}</span>
          <span className="px-2">{currentContent.email}</span>
        </div>

        <div className="flex space-x-4">
          {/* پرچم‌ها */}
          <div
            className={`flex ${
              language === "FA" ? "space-x-reverse space-x-4" : "space-x-4"
            }`}
          >
            <button
              onClick={() => language !== "EN" && changeLanguage("EN")}
              className="relative group inline-block"
            >
              <img
                src={USA_flag}
                alt="English"
                className={`w-6 h-6 hover:opacity-80 ${
                  language === "EN" ? "border-2 rounded" : ""
                }`}
                style={{
                  borderColor: language === "EN" ? "#f6eedf" : "transparent",
                }}
              />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                English
              </span>
            </button>

            <button
              onClick={() => language !== "DE" && changeLanguage("DE")}
              className="relative group inline-block"
            >
              <img
                src={Germany_flag}
                alt="German"
                className={`w-6 h-6 hover:opacity-80 ${
                  language === "DE" ? "border-2 rounded" : ""
                }`}
                style={{
                  borderColor: language === "DE" ? "#f6eedf" : "transparent",
                }}
              />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                German
              </span>
            </button>

            <button
              onClick={() => language !== "FA" && changeLanguage("FA")}
              className="relative group inline-block"
            >
              <img
                src={IR_flag}
                alt="Persian"
                className={`w-6 h-6 hover:opacity-80 ${
                  language === "FA" ? "border-2 rounded" : ""
                }`}
                style={{
                  borderColor: language === "FA" ? "#f6eedf" : "transparent",
                }}
              />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Persian
              </span>
            </button>
          </div>

          <div className="mx-4"></div>

          {/* آیکون‌های شبکه‌های اجتماعی */}
          <div
            className={`flex ${
              language === "FA" ? "space-x-reverse space-x-4" : "space-x-4"
            }`}
          >
            <button className="relative group">
              <FaFacebookF className="text-gray-100 w-6 h-6 hover:opacity-80" />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Facebook
              </span>
            </button>
            <button className="relative group">
              <FaInstagram className="text-gray-100 w-6 h-7 hover:opacity-80" />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Instagram
              </span>
            </button>
            <button className="relative group">
              <CiYoutube className="text-gray-100 w-9 h-9 hover:opacity-80" />
              <span className="absolute bottom-[-1.5rem] left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                Youtube
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { LanguageProvider } from "./context/LanguageContext";
import RegisterCar from "./pages/RegisterCar";
import AboutUs from "./pages/AboutUs";

const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<RegisterCar />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </Router>
    </LanguageProvider>
  );
};

export default App;

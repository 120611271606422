import React from "react";
import { useLanguage } from "../../context/LanguageContext";
import img_car from "../../assets/images/footer_img_car.png";

const Footer = () => {
  const { language } = useLanguage();

  const content = {
    FA: {
      about: "درباره ی ما",
      aboutText:
        "شرکت البرز دیزل، با کادر مجرب و متخصص خود در زمینه خرید، واردات و  اورهال کامیون‌ها، اتوبوس‌ها و خودروها فعالیت می‌کند. ما همچنین  ارائه‌دهنده قطعات و لوازم جانبی اصلی و خدمات تعمیراتی تخصصی برای انواع  خودروهای سنگین  و سبک ",
      quickAccess: {
        title: "دسترسی سریع",
        links: [
          { text: "درباره ما", url: "/" },
          { text: "تماس با ما", url: "/" },
          { text: "ارتباط با مشتری", url: "/" },
        ],
      },
      inPersonServices: {
        title: "خدمات حضوری",
        links: [
          { text: "محصولات", url: "/" },
          { text: "خدمات", url: "/" },
        ],
      },
      onlineServices: {
        title: "خدمات غیر حضوری",
        links: [
          { text: "خرید اینترنتی", url: "/" },
          { text: "فرم همکاری", url: "/" },
          { text: "اخبار", url: "/" },
        ],
      },
    },
    EN: {
      about: "About Us",
      aboutText:
        "Alborz dizel Company, with years of brilliant experience in importing and after-sales services of imported cars, operates as the importer and supplier of Mazda Japan products in Iran.",

      quickAccess: {
        title: "Quick Access",
        links: [
          { text: "Link 1", url: "/" },
          { text: "Link 2", url: "/" },
        ],
      },
      inPersonServices: {
        title: "In-person Services",
        links: [
          { text: "Link 3", url: "/" },
          { text: "Link 4", url: "/" },
        ],
      },
      onlineServices: {
        title: "Online Services",
        links: [
          { text: "Link 5", url: "/" },
          { text: "Link 6", url: "/" },
        ],
      },
    },
    DE: {
      about: "Über uns",
      aboutText:
        "Die Alborz dizel Company, mit jahrelanger Erfahrung im Import und Kundendienst von importierten Autos, ist als Importeur und Lieferant von Mazda Japan Produkten im Iran tätig.",
      quickAccess: {
        title: "Schneller Zugriff",
        links: [
          { text: "Link 1", url: "/" },
          { text: "Link 2", url: "/" },
        ],
      },
      inPersonServices: {
        title: "Persönliche Dienstleistungen",
        links: [
          { text: "Link 3", url: "/" },
          { text: "Link 4", url: "/" },
        ],
      },
      onlineServices: {
        title: "Online-Dienste",
        links: [
          { text: "Link 5", url: "/" },
          { text: "Link 6", url: "/" },
        ],
      },
    },
  };

  const currentContent = content[language] || content["FA"];

  return (
    <footer
      className={`relative bg-black text-white py-8 ${
        language === "FA" ? "rtl" : "ltr"
      }`}
    >
      <div
        className={`absolute top-0 ${
          language === "FA" ? "left-0" : "right-0"
        } w-80 h-60 opacity-40`}
      >
        <img
          src={img_car}
          alt="Car Background"
          className="w-full h-full object-cover"
        />
      </div>

      {/* ستون درباره ی ما */}
      <div
        className="relative container mx-auto grid gap-4"
        style={{ gridTemplateColumns: "2fr 1fr 1fr 1fr" }}
      >
        <div>
          <h2 className="text-lg font-bold mb-2">{currentContent.about}</h2>
          <p className="leading-relaxed text-gray-300 text-sm">
            {currentContent.aboutText}
          </p>
        </div>

        {/* ستون دسترسی سریع */}
        <div>
          <h2 className="text-md font-semibold mb-2">
            {currentContent.quickAccess.title}
          </h2>
          <ul className="space-y-1">
            {currentContent.quickAccess.links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="text-gray-400 hover:text-white text-sm"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* ستون خدمات حضوری */}
        <div>
          <h2 className="text-md font-semibold mb-2">
            {currentContent.inPersonServices.title}
          </h2>
          <ul className="space-y-1">
            {currentContent.inPersonServices.links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="text-gray-400 hover:text-white text-sm"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* ستون خدمات غیر حضوری */}

        <div>
          <h2 className="text-md font-semibold mb-2">
            {currentContent.onlineServices.title}
          </h2>
          <ul className="space-y-1">
            {currentContent.onlineServices.links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="text-gray-400 hover:text-white text-sm"
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect, useCallback } from "react";
import "../../assets/styles/BodySlider.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useLanguage } from "../../context/LanguageContext";
import axios from "axios";

const headers = {
  EN: "News",
  FA: "اخبار",
  DE: "Nachrichten",
};

const BodySlider = () => {
  const { language } = useLanguage();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const itemsPerPage = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await axios.get("http://5.39.223.150:8000/products/", {
        const response = await axios.get("https://dev.jvcjam.com/products/", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        // const response = await axios.get("https://jvcjam.com/products/", {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        setSlides(response.data);
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };

    fetchData();
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + itemsPerPage) % slides.length);
  }, [itemsPerPage, slides.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsPerPage < 0
        ? slides.length - itemsPerPage
        : prevIndex - itemsPerPage
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 30000);

    return () => clearInterval(interval);
  }, [handleNext]);

  const visibleSlides = slides.slice(currentIndex, currentIndex + itemsPerPage);

  return (
    <div className={`body_slider_container ${language === "FA" ? "rtl" : ""}`}>
      <div className="header_body_slider_container">
        <div
          className={`header_body_slider_container_text ${
            language === "FA" ? "rtl-text" : ""
          }`}
        >
          <p>{headers[language]}</p>
        </div>
        <div className="controls_body_slider">
          <button onClick={handlePrev} className="arrow_body_slider left-arrow">
            <IoIosArrowBack />
          </button>
          <button
            onClick={handleNext}
            className="arrow_body_slider right-arrow"
          >
            <IoIosArrowForward />
          </button>
        </div>
      </div>

      <div className="slider_body_slider">
        {visibleSlides.map((slide) => (
          <div key={slide.id} className="slide_body_slider">
            <img src={slide.image} alt={slide.name} />
            <div className="slide_content_body_slider">
              <h3>{slide.name}</h3>
              <p
                className={`${
                  language === "FA"
                    ? "rtl_text_slide_body_slider"
                    : "ltr_text_slide_body_slider"
                }`}
              >
                {slide.description}
              </p>

              <button className="read_more_body_slider">
                {language === "FA"
                  ? "ادامه مطلب"
                  : language === "DE"
                  ? "Weiterlesen"
                  : "Read More"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodySlider;

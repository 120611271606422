import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../../assets/styles/SliderHeader.css";

const SliderHeader = () => {
  const [images, setImages] = useState([]); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    axios
      //.get("http://5.39.223.150:8000/api/slider-images/") 
      .get("https://dev.jvcjam.com/api/slider-images/") 
      .then((response) => {
        setImages(response.data); 
      })
      .catch((error) => {
        console.error("Error fetching slider images:", error);
      });
  }, []);

  const nextSlide = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIsFading(false);
    }, 500);
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, [nextSlide]);

  const prevSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
      setIsFading(false);
    }, 500);
  };

  return (
    <div className="slider">
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      {images.length > 0 && (
        <img
          src={images[currentIndex].image} 
          alt={images[currentIndex].title || "Slider"}
          className={`slide-image ${isFading ? "fade" : ""}`}
        />
      )}
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default SliderHeader;

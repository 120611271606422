import React from "react";
import Header from "../components/specific/Header";
import MenuHeaderDrop from "../components/layout/MenuHeaderDrop";
import SliderHeader from "../components/layout/SliderHeader";
import BodySlider from "../components/layout/BodySlider";
import Footer from "../components/layout/Footer";
import logo from "../assets/images/logo_body.png";

const HomePage = () => {
  return (
    <div>
      <Header />
      <MenuHeaderDrop />
      <SliderHeader />
      <BodySlider />
      <div className="body_logo_top_footer">
        <img src={logo} alt="logo" />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;

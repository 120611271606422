import React from "react";
import { useLanguage } from "../context/LanguageContext";
import Header from "../components/specific/Header";
import MenuHeaderDrop from "../components/layout/MenuHeaderDrop";
import Footer from "../components/layout/Footer";
import "../assets/styles/AboutUs.css";
import pic1 from '../assets/images/aboutpic1.webp'
import pic2 from '../assets/images/aboutpic2.webp'
import pic3 from '../assets/images/aboutpic3.webp'

const aboutUsContent = {
  FA: {
    titleHeader: "اورهال جادویی در البرز دیزل: احیای کامل وسایل نقلیه",
    descHeader: `
    در البرز دیزل، ما هنر احیای وسایل نقلیه را به سطحی فراتر از خدمات فنی صرف ارتقا داده‌ایم. 
    اورهال برای ما فرآیندی هنرمندانه است که زندگی دوباره‌ای به خودروی شما می‌بخشد.
    `,
    serHeader: "خدمات جامع اورهال",
    service1:
      "بازسازی موتور: احیای قلب تپنده خودرو با دقت و ظرافت، از تعمیر سرسیلندر تا تعویض رینگ پیستون‌ها",
    service2:
      "زیبایی بیرونی: صافکاری، نقاشی حرفه‌ای در اتاق رنگ، و نصب قطعات زیبایی",
    service3:
      "نوسازی داخلی: بازسازی کامل فضای داخلی، شامل تعویض صندلی‌ها و نصب سیستم‌های سرگرمی مدرن",
    service4:
      "سیستم‌های حیاتی: اورهال کامل سیستم انتقال قدرت، تعلیق، فرمان و سیستم‌های الکتریکی",
    titleFar: "فرآیند اورهال گام به گام",
    Far1: "صافکاری و نقاشی",
    Far2: "تعمیرات مکانیکی و موتور",
    Far3: "بازسازی جلوبندی و زیربندی",
    Far4: "تعمیرات برقی و باتری",
    Far5: "نوسازی صندلی و اگزوز",
    Far6: "صداگیری اتاق",
    Far7: "صفرشویی نهایی",
    titleKhadamat: "خدمات ویژه",
    Khadamat1:"طراحی سفارشی: شخصی‌سازی کامل داخل و خارج خودرو",
    Khadamat2:"ارتقاء تکنولوژی: نصب جدیدترین سیستم‌های ایمنی و راحتی",
    Khadamat3:"بازسازی کلاسیک: احیای خودروهای کلاسیک با حفظ اصالت",
    Khadamat4:" خدمات زیبایی: پولیش، سرامیک بدنه و کاورهای محافظ",
    titleYadak: "مرکز تخصصی لوازم یدکی",
    Yadak1:
      "موجودی گسترده قطعات اصل برای انواع خودروهای سبک، سنگین، اتوبوس و ماشین‌آلات",
    Yadak2: "همکاری با برندهای معتبر جهانی",
    Yadak3: "امکان سفارش قطعات خاص و کمیاب",
    titleEmkanat: "امکانات منحصر به فرد",
    Emkanat1: "پارکینگ وسیع: فضای بسیار بزرگ برای امنیت و آسایش خاطر شما",
    Emkanat2: "نمایشگاه گسترده: فضایی برای انتخاب از میان صدها گزینه متنوع",
    Emkanat3: "تجهیزات پیشرفته: استفاده از جدیدترین فناوری‌ها در فرآیند اورهال",
    titleTahod: "تعهد ما",
    tahod1: "شفافیت: اطلاع‌رسانی مداوم در هر مرحله از فرآیند اورهال",
    tahod2: "کیفیت بی‌نظیر: استفاده از بهترین قطعات و مواد",
    tahod3: "خلاقیت: ارائه پیشنهادات نوآورانه برای بهبود خودروی شما",
    tahod4: "احترام به اصالت: حفظ هویت اصلی خودرو در عین مدرن‌سازی",
    tahod5: "گارانتی: ارائه ضمانت برای تمامی خدمات و قطعات",
    tahod6: "قیمت رقابتی: بهترین کیفیت با مناسب‌ترین قیمت",
    titleFooter: `در البرز دیزل، ما معتقدیم هر وسیله نقلیه داستانی برای گفتن  دارد. با تیم متخصص و مجرب ما، این داستان را با احترام 
به گذشته و نگاهی  به آینده بازنویسی می‌کنیم. به ما اجازه دهید خودروی شما را به شاهکاری از  زیبایی، عملکرد و نوآوری تبدیل کنیم.`,
  },
  EN: {
    titleHeader: "Magical Overhaul at Alborz Diesel: Complete Vehicle Revival",
    descHeader: `
    At Alborz Diesel, we elevate the art of vehicle restoration beyond mere technical services. 
    For us, overhaul is an artistic process that breathes new life into your vehicle.
    `,
    serHeader: "Comprehensive Overhaul Services",
    service1: "Engine Overhaul: Reviving the beating heart of your vehicle",
    service2:
      "Exterior Refinement: Professional bodywork and painting in a dedicated facility",
    service3: "Interior Renovation: Complete interior restoration and upgrades",
    service4:
      "Vital Systems: Complete overhaul of powertrain, suspension, steering, and electrical systems",
    titleFar: "Step-by-Step Overhaul Process",
    Far1: "Bodywork and Painting",
    Far2: "Mechanical Repairs and Engine Overhaul",
    Far3: "Chassis and Suspension Reconstruction",
    Far4: "Electrical Repairs and Battery Maintenance",
    Far5: "Seat and Exhaust Renovation",
    Far6: "Cabin Noise Reduction",
    Far7: "Final Deep Cleaning",
    titleKhadamat: "Specialized Services",
    Khadamat1:"Custom Design: Full vehicle interior and exterior customization",
    Khadamat2:"Technology Upgrades: Installation of advanced safety and comfort systems",
    Khadamat3:"Classic Restoration: Reviving classic vehicles with attention to authenticity",
    Khadamat4:"Aesthetic Services: Polishing, ceramic coating, and protective covers",

    titleYadak: "Specialized Spare Parts Center",
    Yadak1: "Wide inventory of original parts for various vehicles",
    Yadak2: "Collaboration with global renowned brands",
    Yadak3: "Capability to order rare and specific parts",
    titleEmkanat: "Unique Facilities",
    Emkanat1: "Spacious Parking: Large area ensuring vehicle safety",
    Emkanat2: "Extensive Showroom: Choose from hundreds of options",
    Emkanat3:
      "Advanced Equipment: Using the latest technology in the overhaul process",
    titleTahod: "Our Commitment",
    tahod1: "Transparency: Continuous updates at every step of the process",
    tahod2: "Unparalleled Quality: Using top-grade parts and materials",
    tahod3: "Creativity: Offering innovative suggestions for improvement",
    tahod4:
      "Respect for Authenticity: Maintaining the vehicle’s original identity while modernizing",
    tahod5: "Warranty: Guaranteeing all services and parts",
    tahod6: "Competitive Pricing: Best quality at the most reasonable cost",
    titleFooter: `At Alborz Diesel, we believe every vehicle has a story to tell. 
    With our skilled and experienced team, we rewrite that story with respect for the past 
    and an eye to the future. Let us transform your vehicle into a masterpiece of beauty, performance, and innovation.`,
  },
  DE: {
    titleHeader:
      "Magische Überholung bei Alborz Diesel: Komplette Fahrzeugrevitalisierung",
    descHeader: `
    Bei Alborz Diesel heben wir die Kunst der Fahrzeugrestaurierung über bloße technische Dienstleistungen hinaus. 
    Für uns ist die Überholung ein künstlerischer Prozess, der Ihrem Fahrzeug neues Leben einhaucht.
    `,
    serHeader: "Umfassende Überholungsdienste",
    service1:
      "Motorüberholung: Wiederbelebung des Herzstücks Ihres Fahrzeugs mit Präzision und Sorgfalt",
    service2:
      "Außenveredelung: Professionelle Karosserie- und Lackierarbeiten in einer spezialisierten Werkstatt",
    service3:
      "Innenraumrenovierung: Komplette Restaurierung und Modernisierung des Innenraums",
    service4:
      "Wichtige Systeme: Vollständige Überholung von Antrieb, Federung, Lenkung und Elektrik",
    titleFar: "Schritt-für-Schritt-Überholungsprozess",
    Far1: "Karosseriearbeiten und Lackierung",
    Far2: "Mechanische Reparaturen und Motorüberholung",
    Far3: "Rekonstruktion von Fahrwerk und Federung",
    Far4: "Elektrische Reparaturen und Batteriewartung",
    Far5: "Renovierung von Sitzen und Auspuff",
    Far6: "Geräuschreduzierung im Innenraum",
    Far7: "Abschließende Tiefenreinigung",
    titleKhadamat: "Spezialisierte Dienste",
    Khadamat1:"Individuelles Design: Vollständige Anpassung des Innen- und Außenbereichs des Fahrzeugs",
    Khadamat2:"Technologische Aufrüstung: Installation fortschrittlicher Sicherheits- und Komfortsysteme",
    Khadamat3:"Klassische Restaurierung: Wiederbelebung klassischer Fahrzeuge mit Liebe zur Authentizität",
    Khadamat4:"Ästhetische Dienste: Polieren, Keramikbeschichtung und Schutzabdeckungen",

    titleYadak: "Spezialisiertes Ersatzteilzentrum",
    Yadak1:
      "Breites Sortiment an Originalteilen für verschiedene Fahrzeugtypen",
    Yadak2: "Zusammenarbeit mit renommierten globalen Marken",
    Yadak3: "Möglichkeit, seltene und spezifische Teile zu bestellen",
    titleEmkanat: "Einzigartige Einrichtungen",
    Emkanat1:
      "Großer Parkplatz: Geräumiger Bereich zur Sicherstellung der Fahrzeug-Sicherheit",
    Emkanat2:
      "Großzügiger Ausstellungsraum: Auswahl aus hunderten von Optionen",
    Emkanat3:
      "Fortschrittliche Ausrüstung: Nutzung modernster Technologie im Überholungsprozess",
    titleTahod: "Unser Engagement",
    tahod1:
      "Transparenz: Kontinuierliche Updates bei jedem Schritt des Prozesses",
    tahod2:
      "Unvergleichliche Qualität: Verwendung von hochwertigsten Teilen und Materialien",
    tahod3:
      "Kreativität: Innovative Vorschläge zur Verbesserung Ihres Fahrzeugs",
    tahod4:
      "Respekt für Authentizität: Erhalt der Originalität des Fahrzeugs bei gleichzeitiger Modernisierung",
    tahod5: "Garantie: Gewährleistung für alle Dienstleistungen und Teile",
    tahod6:
      "Wettbewerbsfähige Preise: Beste Qualität zu den günstigsten Preisen",
    titleFooter: `Bei Alborz Diesel glauben wir, dass jedes Fahrzeug eine Geschichte zu erzählen hat. 
    Mit unserem erfahrenen und qualifizierten Team schreiben wir diese Geschichte mit Respekt vor der Vergangenheit 
    und einem Blick in die Zukunft neu. Lassen Sie uns Ihr Fahrzeug in ein Meisterwerk aus Schönheit, Leistung und Innovation verwandeln.`,
  },
};

const AboutUs = () => {
  const { language } = useLanguage();
  const currentContent = aboutUsContent[language] || aboutUsContent.FA;

  return (
    <div>
      <Header />
      <MenuHeaderDrop />

      <div className={`about_us_page ${language === "FA" ? "rtl" : "ltr"}`}>
        <div className="about_us_page_body">

       
        <h1>{currentContent.titleHeader}</h1>
        <p>{currentContent.descHeader}</p>
        <h1>{currentContent.serHeader}</h1>
        <p>{currentContent.service1}</p>
        <p>{currentContent.service2}</p>
        <p>{currentContent.service3}</p>
        <p>{currentContent.service4}</p>
        <img src={pic1} alt=""/>
        <h1>{currentContent.titleFar}</h1>
        <p>{currentContent.Far1}</p>
        <p>{currentContent.Far2}</p>
        <p>{currentContent.Far3}</p>
        <p>{currentContent.Far4}</p>
        <p>{currentContent.Far5}</p>
        <p>{currentContent.Far6}</p>
        <p>{currentContent.Far7}</p>
        <img src={pic2} alt=""/>
        <h1>{currentContent.titleKhadamat}</h1>
        <p>{currentContent.Khadamat1}</p>
        <p>{currentContent.Khadamat2}</p>
        <p>{currentContent.Khadamat3}</p>
        <p>{currentContent.Khadamat4}</p>
        <h1>{currentContent.titleYadak}</h1>
        <p>{currentContent.Yadak1}</p>
        <p>{currentContent.Yadak2}</p>
        <p>{currentContent.Yadak3}</p>
        <h1>{currentContent.titleEmkanat}</h1>
        <p>{currentContent.Emkanat1}</p>
        <p>{currentContent.Emkanat2}</p>
        <p>{currentContent.Emkanat3}</p>
        <img src={pic3} alt=""/>
        <h1>{currentContent.titleTahod}</h1>
        <p>{currentContent.tahod1}</p>
        <p>{currentContent.tahod2}</p>
        <p>{currentContent.tahod3}</p>
        <p>{currentContent.tahod4}</p>
        <p>{currentContent.tahod5}</p>
        <p>{currentContent.tahod6}</p>
        <p className="footer_last_about_us_page">{currentContent.titleFooter}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;

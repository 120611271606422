import React from "react";
import "../../assets/styles/MenuHeaderDrop.css";
import { useLanguage } from "../../context/LanguageContext";

const menuItems = {
  EN: {
    home: { label: "Home", url: "/" },
    registerCar: { label: "Register Car", url: "/register" },
    aboutUs: { label: "About Us", url: "/about-us" },
    products: { label: "Products", url: "/" },
    blog: { label: "Blog", url: "/" },
    services: { label: "Services", url: "/" },
    contact: { label: "Contact", url: "/" },
    subItems: {
      products: [
        { label: "Trucks", url: "/" },
        { label: "Buses", url: "/" },
        { label: "Trailers", url: "/" },
        { label: "Construction Machines", url: "/" },
        { label: "Cars", url: "/" },
        { label: "Spare Parts and Accessories", url: "/" },
        { label: "Special Offers", url: "/" },
        { label: "Vehicle Search", url: "/" },
      ],
    },
  },
  DE: {
    home: { label: "Startseite", url: "/" },
    registerCar: { label: "Auto anmelden", url: "/register" },
    aboutUs: { label: "Über uns", url: "/about-us" },
    products: { label: "Produkte", url: "/" },
    blog: { label: "Blog", url: "/" },
    services: { label: "Dienstleistungen", url: "/" },
    contact: { label: "Kontakt", url: "/" },
    subItems: {
      products: [
        { label: "Lastwagen", url: "/" },
        { label: "Busse", url: "/" },
        { label: "Anhänger", url: "/" },
        { label: "Baumaschinen", url: "/" },
        { label: "Autos", url: "/" },
        { label: "Ersatzteile und Zubehör", url: "/" },
        { label: "Sonderangebote", url: "/" },
        { label: "Fahrzeugsuche", url: "/" },
      ],
    },
  },
  FA: {
    home: { label: "خانه", url: "/" },
    registerCar: { label: "ثبت نام خودرو", url: "/register" },
    aboutUs: { label: "درباره ما", url: "/about-us" },
    products: { label: "محصولات", url: "/" },
    blog: { label: "بلاگ", url: "/" },
    services: { label: "خدمات", url: "/" },
    contact: { label: "تماس", url: "/" },
    subItems: {
      products: [
        { label: "کامیون ها", url: "/" },
        { label: "اتوبوس ها", url: "/" },
        { label: "تریلرها", url: "/" },
        { label: "ماشین آلات ساختمانی", url: "/" },
        { label: "خودرو", url: "/" },
        { label: "قطعات یدکی و لوازم جانبی", url: "/" },
        { label: "پیشنهادات ویژه", url: "/" },
        { label: "جستجوی وسیله ی نقلیه", url: "/" },
      ],
    },
  },
};

function MenuHeaderDrop() {
  const { language } = useLanguage();
  const items = menuItems[language];

  return (
    <div
      className={`menu_header_drop_page ${
        language === "FA" ? "text_right rtl" : "text_left"
      }`}
    >
      <div className="right_menu_header_drop_page">
        {Object.entries(items).map(([key, value]) => {
          if (key === "subItems") return null;

          const subItems = items.subItems?.[key];

          return (
            <div key={key} className="dropdown_menu_header">
              {subItems ? (
                <>
                  <button className="dropbtn_menu_header">{value.label}</button>
                  <div className="dropdown_content_menu_header">
                    {subItems.map((subItem, index) => (
                      <a
                        key={index}
                        href={subItem.url}
                        className="dropdown_link"
                      >
                        {subItem.label}
                      </a>
                    ))}
                  </div>
                </>
              ) : (
                <a href={value.url} className="dropbtn_menu_header">
                  {value.label}
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MenuHeaderDrop;

import React from "react";
import Header from "../components/specific/Header";
import MenuHeaderDrop from "../components/layout/MenuHeaderDrop";
import SliderHeader from "../components/layout/SliderHeader";
import logo from "../assets/images/logo_body.png";
import Footer from "../components/layout/Footer";
import { useLanguage } from "../context/LanguageContext";
import '../assets/styles/RegisterCar.css'

const registerCarTexts = {
    EN: {
      title: "Car Registration",
      fields: {
        fullName: "Full Name",
        fatherName: "Father's Name",
        nationalCode: "National Code",
        birthDate: "Date of Birth",
        veteranCode: "Veteran Code",
        idNumber: "ID Number",
        province: "Province of Residence",
        county: "County of Residence",
        city: "City of Residence",
        mainStreet: "Main Street",
        subStreet: "Sub Street",
        alley: "Alley",
        landline: "Landline Number",
        mobile: "Mobile Number",
        requestedCar: "Requested Car",
      },
      sendButton: "Send",
    },
    DE: {
      title: "Fahrzeugregistrierung",
      fields: {
        fullName: "Vollständiger Name",
        fatherName: "Name des Vaters",
        nationalCode: "Nationale ID",
        birthDate: "Geburtsdatum",
        veteranCode: "Veteranenkode",
        idNumber: "Ausweisnummer",
        province: "Wohnprovinz",
        county: "Wohnbezirk",
        city: "Wohnstadt",
        mainStreet: "Hauptstraße",
        subStreet: "Nebenstraße",
        alley: "Gasse",
        landline: "Festnetznummer",
        mobile: "Handynummer",
        requestedCar: "Gewünschtes Fahrzeug",
      },
      sendButton: "Senden",
    },
    FA: {
      title: "ثبت نام خودرو",
      fields: {
        fullName: "نام و نام خانوادگی",
        fatherName: "نام پدر",
        nationalCode: "کد ملی",
        birthDate: "تاریخ تولد",
        veteranCode: "کد جانبازی",
        idNumber: "شماره شناسنامه",
        province: "استان محل سکونت",
        county: "شهرستان محل سکونت",
        city: "شهر محل سکونت",
        mainStreet: "خیابان اصلی",
        subStreet: "خیابان فرعی",
        alley: "کوچه",
        landline: "شماره تلفن ثابت",
        mobile: "شماره موبایل",
        requestedCar: "خودرو درخواستی",
      },
      sendButton: "ارسال",
    },
  };
  

  function RegisterCar() {
    const { language } = useLanguage(); 
    const texts = registerCarTexts[language];
  
    return (
        <div>
          <Header />
          <MenuHeaderDrop />
          <SliderHeader />
          <div className={`register_car_body ${language === "FA" ? "rtl" : ""}`}>
            <h2>{texts.title}</h2>
            <div className="register_car_body_form">
              {Object.keys(texts.fields).map((key) => (
                <div key={key} className="form_group">
                  <label htmlFor={key}>{texts.fields[key]}</label>
                  <input id={key} name={key} type="text" />
                </div>
              ))}
            </div>
            <div className="register_car_body_form_btn_send">
              <button>{texts.sendButton}</button>
            </div>
          </div>
    
          <div className="body_logo_top_footer">
            <img src={logo} alt="logo" />
          </div>
          <Footer />
        </div>
      );
  }
  
  export default RegisterCar;
